import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import BlueLabel from "../images/logo-blue-small.svg"
import GradientImage from "../images/blue-gradient.png"
import BlueLabelEU from "../images/europe/logo.svg"

const ApplicationProcedureStandard = () => {
  const [steps, setSteps] = useState(() => [true, ...new Array(6).fill(false)])

  function toggleCard(index) {
    setSteps(previousState => {
      return [...previousState].map((step, i) => (i === index ? !step : false))
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={`Cyber Trust ${process.env.GATSBY_VARIANT} Standard Label`} />
      <Layout>
        <div className="application-procedure-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>{`Cyber Trust ${process.env.GATSBY_VARIANT} Standard Label`}</a>
              </li>
            </ul>
          </div>
          <div className="application-procedure">
            <h1>{`Cyber Trust ${process.env.GATSBY_VARIANT} Standard Label`}</h1>
            <div className="application-procedure__wrapper">
              <div className="image" style={{ maxWidth: 125 }}>
                <img
                  src={
                    process.env.GATSBY_VARIANT === "Austria" ? BlueLabel : BlueLabelEU
                  }
                  className="mw-100"
                  alt="Cyber Trust Austria Standard Label"
                  title="Cyber Trust Austria Standard Label"
                />
              </div>
              <h3>{`Process for ordering a Cyber Trust ${process.env.GATSBY_VARIANT} Label`}</h3>
              <div className="page__flex">
                <div className="application-procedure__left">
                  <div className="application-procedure__left--block">
                    <div
                      className={`application-procedure__left--box${
                        steps[0] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(0)}
                      >
                        Online <strong>request</strong>
                      </div>
                      <div className="body">
                        The{" "}
                        <a
                          className="js-popup"
                          onClick={() =>
                            navigate("/en/label-beantragen?label=BLUE")
                          }
                        >
                          request
                        </a>{" "}
                        for the label is done online on this website.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[1] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(1)}
                      >
                        <strong>Answering</strong> the online questionnaire
                      </div>
                      <div className="body">
                        You receive a link to answer the 14 questions of the
                        KSV1870 B-rating. You enter your answers there.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[2] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(2)}
                      >
                        <strong>Automated Web Scoring</strong> (of the submitted
                        qualified domain)
                      </div>
                      <div className="body">
                        An automated security scoring of the domain that you
                        have declared is conducted. This is only passive and not
                        harmful in any way.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[3] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(3)}
                      >
                        <strong>Validation</strong> of answers and calculation
                        of preliminary Cyber Risk Rating
                      </div>
                      <div className="body">
                        Your submitted answers are validated by KSV1870 experts
                        and checked for completeness and plausibility.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[4] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(4)}
                      >
                        <strong>Possibility to clarify</strong> or correct open
                        issues
                      </div>
                      <div className="body">
                        In this phase eventual unclarities or incomplete answers
                        can be clarified and completed.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[5] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(5)}
                      >
                        <strong>Calculation</strong> of final Cyber Risk Rating
                      </div>
                      <div className="body">
                        On the basis of the validated answers the final rating
                        is calculated. You receive a copy of the answers and
                        eventual clarification questions and comments. Your
                        answers are not stored at KSV1870 or Cyber Trust
                        Services due to security reasons.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[6] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(6)}
                      >
                        <strong>Issuing</strong> of the label and recording in
                        the Cyber Trust Label Database
                      </div>
                      <div className="body">
                        If the qualification criteria for the label are met
                        (valid B-rating of 190 or better), you will receive a
                        label confirmation and the usage rights for one year.
                        Your company will also be recorded in the Cyber Trust
                        Label Database.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="application-procedure__right">
                    <div className="sticky">
                      <div className="application-procedure__block application-procedure__block--main">
                        <div className="image">
                          <img src={BlueLabel} className="mw-100" />
                        </div>
                        <div className="name">{`Cyber Trust ${process.env.GATSBY_VARIANT} Labels`}</div>
                        <div className="application-procedure__button">
                          <a
                            onClick={() =>
                              navigate("/en/label-beantragen?label=BLUE")
                            }
                            className="page__btn page__btn--blue js-popup"
                            data-bg="#E2EAFF"
                            data-btnbg="#1854A2"
                            data-child="2"
                            data-rel="rel2"
                          >
                            Request
                          </a>
                          <Link
                            to="/en/list-question-b"
                            className="page__btn page__btn--blue-border"
                          >
                            Requirements
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                <div className="application-procedure__buttons_container">
                  <a
                    onClick={() => navigate("/label-beantragen?label=BLUE")}
                    className="page__btn page__btn--blue js-popup"
                    data-bg="#E2EAFF"
                    data-btnbg="#1854A2"
                    data-child="2"
                    data-rel="rel2"
                  >
                    Request
                  </a>
                  <Link
                    to="/list-question-b"
                    className="page__btn page__btn--blue-border"
                  >
                    Requirements
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ApplicationProcedureStandard
